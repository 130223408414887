* {
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  font-family: IBM Plex Sans, sans-serif !important;
  overflow: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
p,
a,
span {
  font-weight: normal;
}

a:focus {
  text-decoration: underline;
}

.ant-modal-content {
  border-radius: 10px !important;
  overflow: hidden;
}

.ant-modal-confirm-content {
  white-space: pre-line;
}

.ant-tooltip-content .ant-tooltip-inner {
  border-radius: 10px;
  font-size: 12px;
}

.ant-table-column-sorters {
  padding: 0;
}

.ant-select-arrow {
  pointer-events: none;
}

.ant-menu-submenu-popup, .ant-menu-submenu > .ant-menu {
  border-radius: 10px !important;
}

.ant-message-notice-content {
  border-radius: 20px !important;
}

.recharts-tooltip-wrapper {
  z-index: 5;
}

