@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100.ttf');
  src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100italic.ttf');
  src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100italic.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200.ttf');
  src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200italic.ttf');
  src: local('IBM Plex Sans ExtraLight Italic'), local('IBMPlexSans-ExtraLightItalic'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200italic.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300.ttf');
  src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300italic.ttf');
  src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-regular.ttf');
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-italic.ttf');
  src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500.ttf');
  src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500italic.ttf');
  src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600italic.ttf');
  src: local('IBM Plex Sans SemiBold Italic'), local('IBMPlexSans-SemiBoldItalic'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600.ttf');
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700.ttf');
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700italic.ttf');
  src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
       url('./IBMPlexSans/ibm-plex-sans-v8-latin_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* fira-sans-700 - latin_cyrillic */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./FiraSans/fira-sans-v10-latin_cyrillic-700.ttf');
  src: local('Fira Sans Bold'), local('FiraSans-Bold'),
       url('./FiraSans/fira-sans-v10-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./FiraSans/fira-sans-v10-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./FiraSans/fira-sans-v10-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('./FiraSans/fira-sans-v10-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
}